import React, { ReactElement } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '@/components/layout'

function Page(): ReactElement {
  return (
    <Layout>
      <h1>Personlig trening</h1>
      <p>
        Christer Førland som driver Kiropraktor Akutten tilbyr også personlig trening. Med personlig trening gjennom
        Kiropraktor Akutten vil du garantert få en personlig trener som vet hvilke øvelser og hvilken type trening som
        passer deg best.
      </p>
      <h2>Gruppetrening</h2>
      <p>
        Kjernemuskeltrening i små grupper. Dette er et tilbud som vi har både ved Kiropraktor Akuttens avdeling i
        Sandnes og i Stavanger. Treningen har fundament i å styrke og få rett kontroll på de viktigste stabiliserende
        musklene i kroppen. Kiropraktor Akuttens gruppetimer inneholder kjernemuskeltrening, sirkeltrening, viktige
        styrkeøvelser og individuelt tilpasset uttøyning. Det vil være stort fokus på rett utførelse av alle øvelser.
        Varierte øvelser som er spesialtilpasset hver enkelt sitt behov. Treningen er basert på prinsippet om motorisk
        utvikling fra professor Pavel Kolar, P.T., Paed. Dr., Ph.D., som er en av de fremste i verden på funksjonell
        skadeforebyggende trening.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <ul>
            Hensikten med treningen er:
            <li>Bedre holdning</li>
            <li>Mindre slitasje</li>
            <li>Mindre smerter</li>
            <li>Forebygge alle typer smerter fra ledd og muskler</li>
            <li>Gjenopprette optimal muskelbalanse, leddbelastning og motorisk kontroll.</li>
          </ul>
        </div>
        <StaticImage
          src="../images/shutterstock_337161632_web.png"
          alt="Trening"
          layout="fullWidth"
          aspectRatio={1.5}
          // height={200}
          width={150}
          objectFit="cover"
          className="mx-auto md:mt-4 w-full max-w-sm rounded-lg shadow-md"
          imgClassName="rounded-lg"
        />
      </div>
      <h2>Trening og behandling for idrettsutøvere</h2>
      <p>
        For at kroppen som helhet skal fungere optimalt under utøvelse av idrett er det nødvendig at samtlige
        nøkkelområder i kroppen skal fungerer tilfredsstillende. Vi vil tilby en kiropraktisk funksjonsanalyse for å
        undersøke disse områdene nærmere. Hensikten med den kliniske analysen er å avdekke hvilke nøkkelområder som
        krever kiropraktisk behandling. Nedsatt funksjonsnivå behøver ikke å innebære at du som idrettutøver opplever
        noen form for smerte eller ubehag fra bevegelseapparatet under utførelse av din idrett, eller annen form for
        aktivitet.
      </p>
      <p>
        Nedsatt funksjonsnivå hos idrettsutøvere kan lede til: Nedsatt balanse, koordinasjon samt kraftutvikling.
        Nedsatt evne til å håndtere store treningsmengder. Økt risiko for skade. Disse faktorene vil resultere i at
        utøveren ikke kan prestere optimalt.
      </p>
      <p>
        Kiropraktor Akutten avd. Sandnes og Stavanger har som mål å avdekke eventuelle svakheter i funksjonsnivå hos
        idrettsutøveren. Din kiropraktor vil iverksette en behandlingsplan som består av kiropraktisk behandling og
        individuelle rehabiliteringsøvelser for å optimalisere funksjonsevnen din.
      </p>
    </Layout>
  )
}

export default Page
